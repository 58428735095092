// @flow strict
import React from 'react';
import moment from 'moment';
import styles from './Title.module.scss';

type Props = {
  title: string,
  description?: string,
};

const Title = ({ title, description }: Props) => {
  const descrMeta = typeof description !== 'undefined' ? description : '';

  return (
    <div className={styles['title']}>
      <h1 className={styles['title__main']}>{title}</h1>
      <p className={styles['title__description']}>{descrMeta}</p>
    </div>
  );
};

export default Title;
