// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import Title from './Title';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import { useSiteMetadata } from '../../hooks';
import Copyright from '../Copyright';
import SocialShare from '../SocialShare/SocialShare';

type Props = {
  post: Node,
};

const Post = ({ post }: Props) => {
  const siteMeta = useSiteMetadata();
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const {
    tags, title, date, description, category
  } = post.frontmatter;
  // const author = post.frontmatter?.author !== 'undefined' ? post.frontmatter?.author : siteMeta.author.name;
  // const authorImageUrl = post.frontmatter?.authorImageUrl !== 'undefined' ? post.frontmatter?.authorImageUrl : siteMeta.author.photo;
  // const metaAuthor = {
  //   name: author,
  //   photo: authorImageUrl
  // }
  return (
    <div className={styles['post']}>
      <Link className={styles['post__home-button']} to="/">
        Back to Binary Thoughts
      </Link>

      <div className={styles['post__title']}>
        <Title title={title} description={description} />
      </div>

      <div className={styles['post__header_tags']}>
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
      </div>

      <div className={styles['post__header_meta']}>
        <Meta date={date} author={siteMeta.author} timeToRead={post.timeToRead} />
        <SocialShare title={title} description={description} date={date} url={slug} category={category} tags={tags} />
      </div>

      <div className={styles['post__content']}>
        <Content body={html} />
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} />
      </div>

      {/* <div> */}
      <Copyright copyright={siteMeta.copyright} />
      {/* </div> */}
    </div>
  );
};

export default Post;
