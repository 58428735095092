// @flow strict
import React from 'react';
import { withPrefix } from 'gatsby';
import moment from 'moment';
import styles from './Meta.module.scss';

type Props = {
  author: {
    name?: string,
    photo?: string,
  },
  date: string,
  timeToRead?: number,
};

const Meta = ({ author, date, timeToRead }: Props) => (
  <ul className={styles['meta']}>
    <li>
      <img
        src={withPrefix(author.photo)}
        className={styles['meta__author-photo']}
        width="25"
        height="25"
        alt={author.name}
      />
    </li>
    <li className={styles['meta__author-name']}>{author.name}</li>
    <li className={styles['meta__date']}>{moment(date).format('D MMM YYYY')}</li>
    <li className={styles['meta__read']}>{timeToRead} min read</li>
  </ul>
);

export default Meta;
