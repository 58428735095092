// @flow strict
import React from "react";
import styles from "./SocialShare.module.scss";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WorkplaceShareButton,
  WorkplaceIcon,
} from "react-share";
import { Link } from "gatsby";
import { useSiteMetadata } from "../../hooks";

type Props = {
  post: Node,
};

const SocialShare = ({
  title,
  description,
  date,
  url,
  category,
  tags,
}: Props) => {
  const siteMeta = useSiteMetadata();
  const author = useSiteMetadata();
  const postURL = siteMeta.url + url;
  const iconSize = 35;
  const iconRadius = 10;

  const tagsClean = tags.map(function (item) {
    return item.replace(/\s+/g, "_");
  });

  const emailStyle = { fill: "red" };

  return (
    <div className={styles["socialshare"]}>
      <EmailShareButton url={postURL} subject={title}>
        <EmailIcon
          className={styles["socialshare__icon"]}
          size={iconSize}
          borderRadius={iconRadius}
          bgStyle={emailStyle}
        />
      </EmailShareButton>
      <FacebookShareButton url={postURL} hashtag={category}>
        <FacebookIcon
          className={styles["socialshare__icon"]}
          size={iconSize}
          borderRadius={iconRadius}
        />
      </FacebookShareButton>
      <LinkedinShareButton
        url={postURL}
        title={title}
        summary={description}
        source={siteMeta.url}
      >
        <LinkedinIcon
          className={styles["socialshare__icon"]}
          size={iconSize}
          borderRadius={iconRadius}
        />
      </LinkedinShareButton>
      {/* <RedditShareButton url={postURL} title={title}>
          <RedditIcon className={styles["socialshare__icon"]} size={iconSize} borderRadius={iconRadius}/>
      </RedditShareButton> */}
      <TwitterShareButton
        url={postURL}
        title={title}
        via={siteMeta.author.contacts.twitter}
        hashtags={tagsClean}
        related={[siteMeta.author.contacts.twitter]}
      >
        <TwitterIcon
          className={styles["socialshare__icon"]}
          size={iconSize}
          borderRadius={iconRadius}
        />
      </TwitterShareButton>
      <WhatsappShareButton url={postURL} title={title}>
        <WhatsappIcon
          className={styles["socialshare__icon"]}
          size={iconSize}
          borderRadius={iconRadius}
        />
      </WhatsappShareButton>
      {/* <WorkplaceShareButton url={postURL} hashtag={category}>
        <WorkplaceIcon size={iconSize} borderRadius={iconRadius}/>
      </WorkplaceShareButton> */}
    </div>
  );
};

export default SocialShare;
